import _ from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo, useState, useEffect, useContext, useCallback, createContext } from "react";

import { useAuthContext } from "../auth";
import LmsService from "../../services/lms";
import { usePartnerContext } from "../partner";
import { IExam } from "../../services/lms/exam/base/types";
import { ITopic } from "../../services/lms/topic/base/types";
import { ICourse } from "../../services/lms/course/base/types";
import { IModule } from "../../services/lms/module/base/types";
import { IStudentExam } from "../../services/lms/studentExam/base/types";
import { getStudentExamPapersByStudentExamId } from "../../services/lms/studentExamPaper/base";
import { IStudentExamPaper, StudentExamPaperStatus } from "../../services/lms/studentExamPaper/base/types";

// TODO: HH: Define the course, module and topic, correctly.
// ============== TYPE ==============
type PaperGroup = {
    name: string,
    papers: IStudentExamPaper[]
}
type LastViewed = {
    name: string;
    path: string;
}
type IFullCourse = ICourse & {
    modules: IFullModule[];
}
export type IFullModule = IModule & {
    topics: IFullTopic[];
}
export type IFullTopic = ITopic & {
    subTopics: IFullTopic[];
};
export type CourseContextType = {
    loading: boolean;
    course: IFullCourse | undefined;
    module: IFullModule | undefined;
    topic: IFullTopic | undefined;
    moduleTest: IStudentExam | undefined | null;
    courseExams: IStudentExam[] | undefined;
    error: string | undefined;
    paperGroups: PaperGroup[] | undefined;
    lastViewed: LastViewed | undefined;
    path: string;
    moduleTestPapers: IStudentExamPaper[] | undefined;
    examPaperInProgress: { name: string, courseSlug: string, examNumber: string } | undefined;
    allExamIdMap: Record<string, IExam>;
    getStudentExam: () => Promise<void>;
};

// ============== CONTEXT ==============
export const CourseContext = createContext({} as CourseContextType);

// ============== PROVIDER ==============
type Props = {
    children: React.ReactNode;
    wrapperProps: any;
};
export function CourseProvider({ children, wrapperProps }: Props) {

    const { partner } = usePartnerContext();
    const { student } = useAuthContext();

    const lastViewedStr = localStorage.getItem("lastViewed");
    const localLastViewed = lastViewedStr ? JSON.parse(lastViewedStr) : undefined;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [course, setCourse] = useState<any | undefined>(undefined);
    const [module, setModule] = useState<any | undefined>(undefined);
    const [topic, setTopic] = useState<any | undefined>(undefined);
    const [lastViewed, setLastViewed] = useState<LastViewed | undefined>(localLastViewed);
    const [moduleTest, setModuleTest] = useState<IStudentExam | undefined | null>(undefined);
    const [courseExams, setCourseExams] = useState<IStudentExam[] | undefined>(undefined);
    const [moduleTestPapers, setModuleTestPapers] = useState<IStudentExamPaper[] | undefined>(undefined);
    const [paperGroups, setPaperGroups] = React.useState<PaperGroup[] | undefined>(undefined);
    const [examInProgress, setExamInProgress] = useState<IStudentExam | undefined>(undefined);
    const [examPaperInProgress, setExamPaperInProgress] = useState<CourseContextType["examPaperInProgress"]>(undefined);
    const graph = useStaticQuery(graphql`
    query {
        allFullCourse {
            nodes {
              id
              name
              slug
              hours
              desc
              instructions
              accreditationImages {
                id
                name
                title
                url
              }
              courseResources{
                id
                resourceId
                parentId
                courseId
                name
                slug
              }
              modules {
                id
                name
                slug
                hours
                shortName
                youWillNeed
                video
                objective
                instructions
                summary
                learnt
                topics {
                  id
                  name
                  slug
                  content
                  instructions
                  template
                  subTopics {
                    id
                    name
                    slug
                    content
                    instructions
                    tips
                    template
                    assets{
                        id
                        type
                        name
                        desc
                        content
                        sort
                        file{
                            id
                            name
                            title
                            url
                            gatsbyImage {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP]
                                    )
                                }
                                publicURL
                            }
                        }
                        metadata
                    }
                  }
                  assets{
                    id
                    type
                    name
                    desc
                    content
                    sort
                    file{
                        id
                        name
                        title
                        url
                        gatsbyImage {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP]
                                )
                            }
                            publicURL
                        }
                    }
                    metadata
                  }
                  tips
                  learnt
                  video
                }
                papers {
                    id
                    examId
                    name
                    type
                    time
                    totalScore
                    scoreThresholds {
                      green
                      amber
                    }
                    questions {
                      id
                      paperId
                      questionId
                      name
                      sort
                      previousQuestion
                    }
                }
              }
              exams {
                courseId
                examId
                name
                status
                sort
              }
              glossary {
                id
                title
                words {
                    id
                    key
                    definition
                }
              }
              modelContents {
                id
                modelId
                modelContentFields
              }
            }
        }
        allExam {
            nodes {
                id
                name
                slug
                type
                access
                requirements
                openBook
                papers {
                    id
                    paperId
                    examId
                    name
                }
            }
        }
    }`)

    const allCourseMap = _.keyBy(graph.allFullCourse.nodes, 'slug');
    const allCourseIdMap = _.keyBy(graph.allFullCourse.nodes, 'id');
    const allExamIdMap = _.keyBy(graph.allExam.nodes, 'id');

    const getStudentExam = async () => {

        if (!course?.id || !student?.id || !partner?.id) {
            return;
        }

        const response = await LmsService.StudentExam.getStudentExamsByGSI3({ courseId: course.id })

        if (!response.data) {
            return;
        }

        const studentExams = response.data.filter(se => { return se.partnerId === partner.id });
        const examStudentExams: IStudentExam[] = studentExams.filter(se => { return se.examId !== "DYNAMIC" });
        const moduleTestStudentExam: IStudentExam | undefined = studentExams.find(se => { return se.examId === "DYNAMIC" });

        setModuleTest(moduleTestStudentExam || null);
        setCourseExams(examStudentExams);
        if (!examStudentExams || !examStudentExams.length) {
            setExamInProgress(undefined);
        }
        setExamInProgress(examStudentExams.find((ce) => {
            const exam = allExamIdMap[ce.examId];
            return ce.status === "IN_PROGRESS" && exam.openBook !== true
        }));
    }

    const getStudentExamPapers = useCallback(async () => {

        const moduleTestId = moduleTest?.id;
        if (!partner?.id || !student?.id || !moduleTestId) return;

        const response = await getStudentExamPapersByStudentExamId({ partnerId: partner.id, studentExamId: moduleTestId, limit: 1000 });

        const moduleTestExamPapers = response.data;

        setModuleTestPapers(moduleTestExamPapers);

        if (moduleTestExamPapers) {

            const submittedPapers = moduleTestExamPapers.filter(paper => { return paper.status === StudentExamPaperStatus.PASSED || paper.status === StudentExamPaperStatus.FAILED });

            const grouped = submittedPapers.reduce((acc, paper) => {
                if (!acc[paper.moduleName]) {
                    acc[paper.moduleName] = [];
                }
                acc[paper.moduleName].push(paper);
                return acc;
            }, {} as Record<string, IStudentExamPaper[]>);

            const groupedArr: PaperGroup[] = Object.entries(grouped).map(([name, papers]) => {
                return {
                    name,
                    papers: papers.sort((a, b) => {
                        const aCompletedOn = a.completedOn;
                        const bCompletedOn = b.completedOn;
                        if (!aCompletedOn && !bCompletedOn) return 0;
                        if (!aCompletedOn) return 1;
                        if (!bCompletedOn) return -1;
                        return new Date(aCompletedOn).getTime() - new Date(bCompletedOn).getTime();
                    })
                }
            });

            // order by earliest completed
            groupedArr.sort((a, b) => {
                const aCompletedOn = a.papers[0].completedOn;
                const bCompletedOn = b.papers[0].completedOn;
                if (!aCompletedOn && !bCompletedOn) return 0;
                if (!aCompletedOn) return 1;
                if (!bCompletedOn) return -1;
                return new Date(aCompletedOn).getTime() - new Date(bCompletedOn).getTime();
            });

            setPaperGroups(groupedArr);
        }
    }, [student?.id, moduleTest?.id, partner?.id, setPaperGroups])

    useEffect(() => {
        getStudentExam();
    }, [student, course, partner?.id])

    useEffect(() => {
        // HH: If on a page that has a course in the path or glossary
        let location = wrapperProps.path;
        location = location.replaceAll("/:id", `/${wrapperProps.params.id}/`);
        const isCoursePage = location.match(/\/((classroom)|(glossary))\/[^/]+\//g);

        let lastViewed: LastViewed | undefined;
        if (isCoursePage) {
            const slug = location.split('/')[2];
            const newCourse = { ...allCourseMap[slug] };
            newCourse.id = newCourse.id.replace("FullCourse-", "");
            const onExamOrExamPaperPage = (wrapperProps.path.match(/\/classroom\/.*\/exam\/[1-9]+/g));
            const onTestPageOrRootPage = (wrapperProps.path.match(/\/classroom\/.*\/.*\/module-test/g) || wrapperProps.path.match(/\/classroom\/.*/g))
            if (newCourse.id !== course?.id || onExamOrExamPaperPage || onTestPageOrRootPage) {
                setCourse(newCourse);
            }
            // HH: If on a page that has module in the path
            const isModulePage = location.match(/\/classroom\/[^/]+\/[^/]+\//g) &&
                !location.match(/\/classroom\/[^/]+\/exam\//g) &&
                !location.match(/\/classroom\/[^/]+\/progress\//g) &&
                !location.match(/\/classroom\/[^/]+\/resources\//g) &&
                !location.match(/\/classroom\/[^/]+\/videos\//g) &&
                !location.match(/\/classroom\/[^/]+\/glossary\//g);

            if (isModulePage) {
                const slug = location.split('/')[3];

                const newModule = newCourse.modules.find((module: any) => { return module.slug === slug });
                newModule.id = newModule.id.replace("FullModule-", "");

                if (newModule.id !== module?.id) {
                    setModule(newModule);
                }

                lastViewed = { name: newModule.name, path: location };

                // HH: If on a page that has topic in the path
                const isTopicPage = location.match(/\/classroom\/[^/]+\/[^/]+\/[^/]+\//g) && !location.match(/\/classroom\/[^/]+\/[^/]+\/module-test\//g) && !location.match(/\/classroom\/[^/]+\/[^/]+\/summary\//g)
                if (isTopicPage) {
                    const isTopicSummaryPage = location.match(/\/classroom\/[^/]+\/[^/]+\/[^/]+\/summary\//g)
                    const isSubTopicPage = location.match(/\/classroom\/[^/]+\/[^/]+\/[^/]+\/[^/]+\//g);
                    let topicSlug = "";
                    let newTopic: any;
                    topicSlug = location.split('/')[4];
                    newTopic = newModule?.topics.find((moduleTopic: any) => { return moduleTopic.slug === topicSlug });

                    if (isSubTopicPage && !isTopicSummaryPage) {
                        // Sub Topic
                        const subTopicSlug = location.split('/')[5];
                        newTopic = newTopic?.subTopics.find((moduleTopic: any) => { return moduleTopic.slug === subTopicSlug });
                    }

                    newTopic.id = (newTopic.id || "").replace("FullTopic-", "");
                    if (newTopic.id !== topic?.id) {
                        setTopic(newTopic);
                    }
                    lastViewed = { name: newTopic.name, path: location };
                }
            }
        } else {
            setCourse(undefined);
            setModule(undefined);
            setTopic(undefined);
        }
        if (lastViewed) {
            localStorage.setItem("lastViewed", JSON.stringify(lastViewed));
            setLastViewed(lastViewed);
        }

    }, [wrapperProps.path])

    useEffect(() => {
        setExamPaperInProgress(undefined);
        getExamPaperInProgress();
    }, [examInProgress, partner])

    const getExamPaperInProgress = async () => {
        if (!examInProgress || !partner) {
            setExamPaperInProgress(undefined);
            return;
        }

        const examPapersForExamInProgress = await getStudentExamPapersByStudentExamId({ partnerId: partner.id, studentExamId: examInProgress.id });

        if (!examPapersForExamInProgress.data) {
            setExamPaperInProgress(undefined);
            return;
        }

        const examInProgressInCourseExamsIndex = courseExams?.findIndex((ce) => ce.id === examInProgress.id);
        if (!examInProgressInCourseExamsIndex && examInProgressInCourseExamsIndex !== 0) {
            // HH: This should never happen
            setExamPaperInProgress(undefined);
            return;
        }

        const examNumber = `${examInProgressInCourseExamsIndex + 1}`;
        const courseSlug = allCourseIdMap[`FullCourse-${examInProgress.courseId}`]?.slug;
        const examPaperInProgressUpdate = examPapersForExamInProgress.data.filter((ep) => ep.status === "IN_PROGRESS")?.[0];
        if (examPaperInProgressUpdate) {
            setExamPaperInProgress({ name: examPaperInProgressUpdate.name, examNumber, courseSlug });
        }
    }

    useEffect(() => {
        getStudentExamPapers();
    }, [getStudentExamPapers, moduleTest?.papersPassed])

    const memoizedValue: CourseContextType = useMemo(
        () => {
            return {
                loading,
                course,
                module,
                topic,
                moduleTest,
                error,
                moduleTestPapers,
                courseExams,
                paperGroups,
                lastViewed,
                path: wrapperProps.path,
                examPaperInProgress,
                allExamIdMap,
                getStudentExam
            }
        },
        [error, loading, getStudentExam, course, module, topic, moduleTest, courseExams, paperGroups, lastViewed, wrapperProps.path, moduleTestPapers, examPaperInProgress]
    );

    return (
        <CourseContext.Provider value={memoizedValue}>
            {children}
        </CourseContext.Provider>
    );
}

// ============== HOOK ==============
export const useCourseContext = () => {
    const context = useContext(CourseContext);
    if (!context)
        throw new Error("useCourseContext context must be use inside CourseProvider");
    return context;
};
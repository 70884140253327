import React, { useMemo } from 'react'

import Card from './card';
import Button from './button';
import Text from './Fields/Text';
import SanitisedHTML from './sanitisedHTML';
import { usePartnerContext } from '../context/partner';
import { IWord } from '../services/lms/word/base/types';

interface Props {
    course: any
}

export default function Glossary({ course }: Props) {

    const [letterIndex, setLetterIndex] = React.useState<string>('All');
    const { colors } = usePartnerContext();
    const [search, setSearch] = React.useState<string>('');

    const wordsIndexed: Record<string, IWord[]> = {};

    course?.glossary?.words.forEach((word: IWord) => {
        if (!wordsIndexed[word.key.charAt(0).toLowerCase()]) {
            wordsIndexed[word.key.charAt(0).toLowerCase()] = [word];
        } else {
            wordsIndexed[word.key.charAt(0).toLowerCase()].push(word);
        }
    })

    const wordsForLetter = useMemo(() => {
        let words: IWord[] | undefined = [];

        if (letterIndex === "All") {
            words = course?.glossary?.words?.sort((a: IWord, b: IWord) => { return a.key.localeCompare(b.key) });
        } else {
            words = wordsIndexed?.[letterIndex].sort((a, b) => { return a.key.localeCompare(b.key) });
        }

        if (search) {
            words = words?.filter((word: IWord) => word.key.toLowerCase().match(search.toLowerCase()) || word.definition.toLowerCase().match(search.toLowerCase()));
        }

        if ((words?.length || 0) === 0) {
            return undefined;
        }

        return words;
    }, [letterIndex, course?.glossary?.words, search])

    return (<>
        {!course?.glossary && (
            <Card>
                <p className={"text-center"}>
                    It seems you have no glossary for this course.
                </p>
            </Card>
        )}
        {course?.glossary && <div className={"space-y-2"}>

            <div className={"flex flex-wrap justify-center p-3 gap-2"}>
                <Text clearable placeholder={"Search Glossary..."} value={search} onChange={(e) => setSearch(e.target.value)} />
                <Button padding={"px-2 py-1"} variant={letterIndex === "All" ? "" : "ghost"} key={`glossary-all`} onClick={() => { setLetterIndex("All"); }}>All</Button>
                {[...'abcdefghijklmnopqrstuvwxyz'].map((letter) => {
                    const hasWords = (wordsIndexed[letter]?.length || 0) > 0
                    return <Button padding={"px-2 py-1"} disabled={!hasWords} variant={letterIndex === letter ? "" : "ghost"} key={`glossary-${letter}`} onClick={() => { setLetterIndex(letter); }}>{letter.toUpperCase()}</Button>
                })}
            </div>

            <div className={"border-t-2 p-3"}>
                {!wordsForLetter && <div className={"flex flex-col mx-auto w-full"}>
                    <p className={"mx-auto pb-4"}>No words found for this letter{search ? " based on your search." : "."}</p>
                    <div className={"flex flex-row gap-2 w-fit m-auto"}>
                        {(search && letterIndex !== "All") && <Button padding={"px-2 py-1 w-fit mx-auto"} type={"button"} onClick={(e) => { setLetterIndex("All") }}> Searching the entire glossary</Button>}
                        {(search) && <Button padding={"px-2 py-1 w-fit mx-auto"} type={"button"} onClick={(e) => { setSearch('') }}> Clear search</Button>}
                    </div>
                </div>}
                {!!wordsForLetter && (
                    <table key={`glossary-table-${letterIndex}`} className={"table-auto w-full"}>
                        {wordsForLetter.map((word: IWord, index: number) => {
                            return <tr style={index ? { borderTop: `2px ${colors.gray2} solid` } : {}} key={word.key}>
                                <td className={"p-2 font-bold"}>{word.key}</td>
                                <td className={"p-2"}>
                                    <SanitisedHTML text={word.definition} />
                                </td>
                            </tr>
                        })}
                    </table>
                )}

            </div>
        </div>}
    </>)
}

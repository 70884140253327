import React, { useMemo, useState, useEffect, useContext, useCallback, createContext } from "react";

import { DEFAULT_COLORS } from "./constants";
import PlatformService from "../../services/platform";
import ServiceDeskService from "../../services/service-desk";
import { IPartner } from "../../services/platform/partner/base/types";
import { IMailbox } from "../../services/service-desk/mailbox/base/types";
import { PartnerColors, PartnerContextType, PartnerDashboardSettings } from "./types";

// ============== CONTEXT ==============
export const PartnerContext = createContext({} as PartnerContextType);

// ============== PROVIDER ==============
type Props = {
    children: React.ReactNode;
};
export function PartnerProvider({ children }: Props) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>(undefined);
    const [partner, setPartner] = useState<IPartner | undefined>(undefined);
    const [mailbox, setMailbox] = useState<IMailbox | undefined>(undefined);

    const initialize = useCallback(async () => {

        if (partner) {
            return;
        }

        const subdomain = process.env.GATSBY_FORCE_PARTNER_SUBDOMAIN || window.location.hostname.split(".")[0];

        if (!subdomain) {
            setError("No subdomain found");
            return;
        }

        const partners = await PlatformService.Partner.getPartnerBySubdomain(subdomain);

        if (partners.length === 0) {
            setError("No partner found");
            return;
        }

        const newPartner = partners[0];

        setPartner(newPartner);
        setLoading(false);

    }, [partner]);


    const loadMailbox = useCallback(async () => {
        if (!partner) {
            return;
        }

        const mailboxResponse = await ServiceDeskService.Mailbox.getMailboxByPartnerId(partner.id);

        if (!mailboxResponse?.success || !mailboxResponse?.data) {
            console.warn("Failed to get mailbox for partner", partner.id);
            return;
        };

        if (mailboxResponse.data.length && mailboxResponse.data[0] && mailboxResponse.data[0].id) {
            setMailbox(mailboxResponse.data[0]);
        }
    }, [partner?.id]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const colors: PartnerColors = useMemo(
        () => {
            const colorsObj = JSON.parse(partner?.colors || "{}") as Partial<PartnerColors>;
            Object.keys(colorsObj).forEach((key: any) => {
                const value = (colorsObj as any)[key];
                if (!value && value === "") {
                    delete (colorsObj as any)[key];
                }
            });
            return {
                ...DEFAULT_COLORS,
                ...colorsObj
            };
        },
        [partner]
    );

    const dashboardSettings: PartnerDashboardSettings = useMemo(
        () => {
            return JSON.parse(partner?.dashboardSettings || "{}") as PartnerDashboardSettings;
        },
        [partner]
    );

    const memoizedValue: PartnerContextType = useMemo(
        () =>
        ({
            loading,
            partner,
            error,
            colors,
            dashboardSettings,
            mailbox,
            loadMailbox
        }),
        [error, mailbox, loading, partner, dashboardSettings, colors, loadMailbox]
    );

    return (
        <PartnerContext.Provider value={memoizedValue}>
            {children}
        </PartnerContext.Provider>
    );
}

export const usePartnerContext = () => {
    // ============== HOOK ==============
    const context = useContext(PartnerContext);
    if (!context)
        throw new Error("usePartnerContext context must be use inside PartnerProvider");
    return context;
};
import { Link, navigate } from 'gatsby';
import React, { useMemo, useState } from 'react'

import Search from './Search';
import MobileMenu from './MobileMenu';
import NewEmailBar from './NewEmailBar';
import Modal from '../../components/modal';
import UTIcon from '../../components/icons';
import Button from '../../components/button';
import Glossary from '../../components/glossary';
import { useAuthContext } from '../../context/auth';
import useWindowSize from '../../hooks/useWindowSize';
import { useCourseContext } from '../../context/course';
import { usePartnerContext } from '../../context/partner'

const ICON_SIZE = 18;

export default function AppHeader() {

    const { course } = useCourseContext();
    const { partner, colors } = usePartnerContext();
    const { working, loading, signOut } = useAuthContext();
    const { isMobile } = useWindowSize();

    const [emailForm, setEmailForm] = useState(false);

    const [showMenu, setShowMenu] = useState(false);
    const [showGlossary, setShowGlossary] = useState(false);

    const rightHeaderColor = colors?.secondaryText;

    const headerLogo = useMemo(() => {
        if (partner?.logo?.url) {
            return <img src={partner.logo.url} alt={partner?.name} className={"h-[50px] py-[10px] object-contain"} />
        }
        return <h1 className={"text-lg font-bold"}>
            {partner?.name}
        </h1>
    }, [partner]);

    return (
        <>
            <header className={"py-[10px]"} style={{
                backgroundColor: colors?.secondary,
                color: colors?.secondaryText
            }}>
                <div className={"flex space-x-5 items-center px-5"}>
                    <div>
                        <Link to={"/"}>
                            {headerLogo}
                        </Link>
                    </div>
                    <div className={"flex-1"}>


                        {!isMobile && (
                            <nav className={"flex justify-end items-center space-x-1 md:space-x-2"}>
                                <Search />
                                {course && <HeaderButton onClick={() => { navigate(`/classroom/${course.slug}/glossary`) }} disabled={working || loading}>
                                    <UTIcon type={"glossary"} color={rightHeaderColor} size={ICON_SIZE} />
                                </HeaderButton>}
                                <HeaderButton onClick={() => { setEmailForm(!emailForm) }} disabled={working || loading}>
                                    <UTIcon type={"message"} color={rightHeaderColor} size={ICON_SIZE} />
                                </HeaderButton>
                                <HeaderButton onClick={() => { navigate("/my-account") }} disabled={working || loading}>
                                    <UTIcon type={"user"} color={rightHeaderColor} size={ICON_SIZE} />
                                </HeaderButton>
                                <HeaderButton onClick={() => { navigate("/help/user-guide"); }} disabled={working || loading}>
                                    <UTIcon type={"question"} color={rightHeaderColor} size={ICON_SIZE} />
                                </HeaderButton>
                                <HeaderButton disabled={working || loading} onClick={signOut}>
                                    {(working || loading) && <UTIcon color={rightHeaderColor} type={"spinner2"} size={ICON_SIZE} className={"animate-spin"} /> || <UTIcon color={rightHeaderColor} type={"signout"} size={ICON_SIZE} />}
                                </HeaderButton>
                            </nav>
                        )}

                        {isMobile && (
                            <>
                                <div className={"flex justify-end items-center space-x-1"}>
                                    <button type={'button'} onClick={() => { setEmailForm(true) }} className={"px-3 flex items-center space-x-3"}>
                                        <UTIcon type={"message"} color={rightHeaderColor} size={ICON_SIZE} />
                                    </button>
                                    <button type={'button'} onClick={() => { return setShowMenu(true) }} className={"px-3 flex items-center space-x-3"}>
                                        <UTIcon type={"menu"} color={rightHeaderColor} size={ICON_SIZE} />
                                    </button>
                                </div>

                                {showMenu && <MobileMenu setShowGlossary={setShowGlossary} onClose={() => { return setShowMenu(false) }} />}
                            </>
                        )}
                    </div>
                </div>

                <NewEmailBar emailForm={emailForm} setEmailForm={setEmailForm} />

            </header>
            <Modal
                header={"Glossary"}
                className={"h-full w-9/12 min-h-[75vh] min-w-[75vw]"}
                show={showGlossary}
                onClose={() => { setShowGlossary(false) }}
                showXButton
            >
                <Glossary course={course} />
            </Modal>
        </>
    )
}

const HeaderButton = ({ children, onClick, disabled }: { children: React.ReactNode, onClick: () => void, disabled?: boolean }) => {
    return (
        <Button
            variant={"ghost"}
            padding={"py-2 px-3"}
            className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={onClick}
            disabled={disabled}
            textColor={"primary"}
        >
            {children}
        </Button>
    )
}
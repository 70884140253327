export const ASSET_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment AssetFields on Asset {
    id
    tenantId
    type
    name
    content
    desc
    file {
      id
      name
      url
      title
    }
    status
    metadata
    cb
    sb
    co
    so
  }
`;

export const CREATE_ASSET = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  mutation CreateAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      ...AssetFields
    }
  }
`;

export const UPDATE_ASSET = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      ...AssetFields
    }
  }
`;
export const DELETE_ASSET = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  mutation DeleteAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      ...AssetFields
    }
  }
`;

export const GET_ASSET = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  query GetAsset($input: GetAssetInput!) {
    getAsset(input: $input) {
      ...AssetFields
    }
  }
`;

export const GET_ASSET_WITH = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  query GetAssetWith($input: GetAssetInput!) {
    getAssetWith(input: $input) {
      ...AssetFields
    }
  }
`;

export const GET_BY_ASSET_NAME = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  query GetByAssetName($input: GetByNameAssetInput!) {
    getByAssetName(input: $input) {
      data {
        ...AssetFields
      }
      nextToken
    }
  }
`;

export const STUDENT_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment StudentFields on Student {
    id
    firstName
    lastName
    email
    phoneNumber {
      code
      number
    }
    cognitoUser
    noReview
    studentCourses {
      id
      studentId
      courseId
      partnerId
      type
      name
      expiresOn
      hasAccessToExam
      examsPassed
      co
    }
    partnerId
    addresses {
      id
      contactName
      studentId
      student {
        id
        firstName
        lastName
        email
      }
      line1
      line2
      city
      postCode
      stateCode
      state
      countryCode
      country
      lat
      long
    }
  }
`;
export const GET_STUDENT_EMAIL_WEBSITE = /* GraphQL */ `
  query GetByStudentEmailWebsite($input: GetByEmailStudentInput!) {
    getByStudentEmailWebsite(input: $input) {
      cognitoUsername
    }
  }
`;
export const GET_MY_STUDENT = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  query GetMyStudent {
    getMyStudent {
      ...StudentFields
    }
  }
`;
export const GET_CREATE_STUDENT_ADDRESS = /* GraphQL */ `
  mutation GetCreateStudentAddress($input: GetCreateStudentAddressInput!) {
    getCreateStudentAddress(input: $input) {
      id
      studentId
      student {
        id
        firstName
        lastName
        email
      }
      line1
      line2
      city
      postCode
      countryCode
      country
    }
  }
`;
export const DELETE_STUDENT_ADDRESS = /* GraphQL */ `
  mutation deleteStudentAddress($input: DeleteStudentAddressInput!) {
    deleteStudentAddress(input: $input) {
      id
      studentId
      student {
        id
        firstName
        lastName
        email
      }
      line1
      line2
      city
      postCode
      countryCode
      country
    }
  }
`;

export const CREATE_STUDENT = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  mutation CreateStudent($input: CreateStudentInput!) {
    createStudent(input: $input) {
      ...StudentFields
    }
  }
`;

export const CREATE_STUDENT_WITH_COGNITO = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  mutation CreateStudentWithCognito($input: CreateStudentInput!) {
    createStudentWithCognito(input: $input) {
      ...StudentFields
    }
  }
`;

export const GET_STUDENT = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  query GetStudent($input: GetStudentInput!) {
    getStudent(input: $input) {
      ...StudentFields
    }
  }
`;

export const GET_STUDENT_WITH = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  query GetStudentWith($input: GetStudentInput!) {
    getStudentWith(input: $input) {
      ...StudentFields
    }
  }
`;

export const UPDATE_STUDENT = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  mutation UpdateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      ...StudentFields
    }
  }
`;

export const UPDATE_STUDENT_WITH_COGNITO = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  mutation UpdateStudentWithCognito($input: UpdateStudentInput!) {
    updateStudentWithCognito(input: $input) {
      ...StudentFields
    }
  }
`;

export const DELETE_STUDENT = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  mutation DeleteStudent($input: DeleteStudentInput!) {
    deleteStudent(input: $input) {
      ...StudentFields
    }
  }
`;

export const GET_STUDENTS = /* GraphQL */ `
  ${STUDENT_FIELDS_FRAGMENT}
  query GetStudents($input: GetStudentsInput!) {
    getStudents(input: $input) {
      data {
        ...StudentFields
      }
      nextToken
    }
  }
`;

import React, { useEffect, ReactElement } from "react";

import UTIcon from "./icons";
import Button from "./button";
import { CardProps } from "./card";
import { usePartnerContext } from "../context/partner";

type ModalProps = {
    children?: ReactElement,
    show: boolean,
    header?: string,
    buttons?: ReactElement,
    color?: CardProps["color"],
    icon?: CardProps["icon"],
    padding?: string,
    modalPadding?: string,
    className?: string,
    onClose?: () => void,
    onConfirm?: () => void,
    showXButton?: boolean
    maxWidth?: string
}

const Modal = ({ children, className = "", show, maxWidth, color, header = undefined, buttons = undefined, icon = undefined, padding = "py-5", modalPadding = "px-5", onClose = undefined, onConfirm = () => { }, showXButton }: ModalProps) => {

    const { colors } = usePartnerContext();

    useEffect(() => {
        const value = show ? 'hidden' : 'auto';
        document.querySelector('body')?.setAttribute('style', `overflow: ${value};`);
        return () => {
            // set window overflow auto
            document.querySelector('body')?.setAttribute('style', 'overflow: auto;');
        }
    }, [show])

    if (!show) {
        return null;
    }

    return <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center`}
        onClick={onClose}
    >
        <div
            className={`relative bg-white ${className} rounded-md max-h-[75vh]`}
            style={{
                boxShadow: "0 5px 15px rgba(0, 0, 0, .25)",
                maxWidth: maxWidth || "75vw"
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {showXButton && (
                <a
                    href={"#"}
                    className={`
                            absolute top-1 right-1 p-2 z-50
                            bg-white rounded-full shadow-md
                            transform translate-x-1/2 -translate-y-1/2                    
                        `}
                    onClick={onClose}
                >
                    <UTIcon type={"close"} size={20} color={colors.primary} />
                </a>
            )}
            <div
                className={`
                relative overflow-auto h-full w-full
                space-y-4
                ${padding}
            `}>
                {header && (
                    <div className={"px-5"} style={{ color: colors.textColor }}>
                        <div className={"flex items-center justify-between space-x-3"}>
                            <div className={"flex items-center space-x-3"}>
                                {icon && (
                                    <UTIcon color={colors.textColor} type={icon} />
                                )}
                                <h2 className={"text-2xl font-bold"}>
                                    {header}
                                </h2>
                            </div>
                            <div>
                                {!showXButton && onClose && (
                                    <Button onClick={onClose} padding={"px-2 py-1"} variant={"ghost"}>
                                        <UTIcon type={"close"} />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className={`${modalPadding}`}>
                    {children}
                </div>
                {buttons && (
                    <div className={"px-5"}>
                        {buttons}
                    </div>
                )}
            </div>
        </div>
    </div>
}

export default Modal;
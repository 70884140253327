export const CONVERSATION_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ConversationFields on Conversation {
    id
    mailboxId
    tenantId
    subject
    status
    toUnread
    fromUnread
    contact {
      id
      name
      email
      firstName
      lastName
    }
    contactId
    co
    latestMessageTimestamp
  }
`;

export const GET_MY_CONVERSATIONS = /* GraphQL */ `
  ${CONVERSATION_FIELDS_FRAGMENT}
  query GetMyConversations($input: GetMyConversationsInput!) {
    getMyConversations(input: $input) {
      data {
        ...ConversationFields
      }
      nextToken
    }
  }
`;

export const GET_CONVERSATION = /* GraphQL */ `
  ${CONVERSATION_FIELDS_FRAGMENT}
  query GetConversation($input: GetConversationInput!) {
    getConversation(input: $input) {
      ...ConversationFields
    }
  }
`;

export const UPDATE_CONVERSATION = /* GraphQL */ `
  ${CONVERSATION_FIELDS_FRAGMENT}
  mutation UpdateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      ...ConversationFields
    }
  }
`;
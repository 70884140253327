export const DEFAULT_COLORS = {
  primary: "green",
  secondary: "#000",
  primaryText: "#000",
  secondaryText: "#fff",
  black: "#000000",
  white: "#ffffff",
  success: "#a7c838",
  successText: "#000",
  danger: "red",
  dangerText: "#fff",
  warning: "#ffa500",
  warningText: "#fff",
  info: "#d9edf7",
  infoText: "#1c6386",
  gray1: "#f5f5f5",
  gray2: "#ddd",
  gray3: "#ccc",
  gray4: "#bbb",
  gray5: "#aaa",
  gray6: "#999",
  gray7: "#888",
  gray8: "#777",
  gray9: "#666",
  gray10: "#555",
  gray11: "#444",
  gray12: "#333",
  textColor: "#333",
  backgroundGray: "#efecec",
};

import _ from "lodash";
import React from "react";
import { MdKeyboardReturn, MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { PiSpinner, PiPlusCircle, PiMinusCircle, PiXCircleDuotone, PiSpinnerGapThin, PiCaretDoubleLeft, PiCaretDoubleRight, PiBookOpenTextDuotone, PiWarningCircleDuotone } from "react-icons/pi";
import { FaSync, FaSave, FaTimes, FaImage, FaCircle, FaArchive, FaFileAlt, FaBookOpen, FaInfoCircle, FaSignOutAlt, FaCaretRight, FaCheckCircle, FaCheckSquare, FaTimesCircle, FaQuestionCircle, FaArrowCircleLeft, FaArrowCircleRight, FaExclamationCircle } from "react-icons/fa";
import { FaBars, FaBook, FaFile, FaPlus, FaUser, FaCheck, FaGauge, FaMinus, FaVideo, FaXmark, FaAnchor, FaFolder, FaPencil, FaFilePdf, FaEnvelope, FaRegClock, FaSailboat, FaBookAtlas, FaRegSquare, FaCaretDown, FaAngleRight, FaRegEnvelope, FaGraduationCap, FaRegEnvelopeOpen } from "react-icons/fa6";

import { usePartnerContext } from "../context/partner";

// HH: Here is where we define the icons that we use for this application. We should only ever use this component to render icons.
type urbanTrauntTermsIcons = "classroom" | "course" | "module" | "topic" | "asset" | "glossary" | "exam" | "examPaper" | "moduleTest" | "message" | "openMessage" | "user" | "instructorTips" | "resources" | "almanac" | "return";
type commonApplicationIcons = "close" | "refresh" | "closeCircle" | "folder" | "minus2" | "book" | "plus2" | "plus" | "progress" | "minus" | "caretRight" | "caretDoubleLeft" | "caretDoubleRight" | "accordionOpen" | "accordionClosed" | "warning" | "signout" | "error" | "check" | "checkCircle" | "cross" | "question" | "info" | "spinner" | "spinner2" | "clock" | "radioChecked" | "radioUnchecked" | "arrow-left" | "arrow-right" | "menu" | "checkbox" | "checkboxChecked" | "mail" | "pencil" | "clear" | "save" | "file-picture" | "triangle" | "circle";
type metaDataIcons = "video" | "pdf";
type miscIcons = "sailboat"
type IconTypes = urbanTrauntTermsIcons | commonApplicationIcons | metaDataIcons | miscIcons;

export type UTIconProps = {
    type: IconTypes;
    size?: number;
    className?: string;
    color?: string;
    extra?: IconTypes;
    onClick?: () => void;
    divClassName?: string;
}

const UTIcon = ({ type, size = 24, className = undefined, color = undefined, extra = undefined, onClick = () => { }, divClassName = undefined }: UTIconProps) => {
    const { colors } = usePartnerContext();

    if (!color) {
        color = colors.textColor;
    }

    if (color === "primary") {
        color = colors.primary;
    }

    if (color === "danger") {
        color = colors.danger;
    }

    if (color === "warning") {
        color = colors.warning;
    }

    if (color === "success") {
        color = colors.success;
    }

    let icon;

    // TODO: HH: GW/OF should review/finalise the chosen icons
    switch (type) {
        case "classroom": icon = <FaGraduationCap size={size} className={className} color={color} />; break;
        case "course": icon = <FaGauge size={size} className={className} color={color} />; break;
        case "module": icon = <FaGauge size={size} className={className} color={color} />; break;
        case "topic": icon = <FaFileAlt size={size} className={className} color={color} />; break;
        case "asset": icon = <FaFile size={size} className={className} color={color} />; break;
        case "glossary": icon = <FaBook size={size} className={className} color={color} />; break;
        case "close": icon = <FaTimes size={size} className={className} color={color} />; break;
        case "closeCircle": icon = <FaTimesCircle size={size} className={className} color={color} />; break;
        case "plus2": icon = <FaPlus size={size} className={className} color={color} />; break;
        case "plus": icon = <PiPlusCircle size={size} className={className} color={color} />; break;
        case "minus": icon = <PiMinusCircle size={size} className={className} color={color} />; break;
        case "minus2": icon = <FaMinus size={size} className={className} color={color} />; break;
        case "message": icon = <FaRegEnvelope size={size} className={className} color={color} />; break;
        case "openMessage": icon = <FaRegEnvelopeOpen size={size} className={className} color={color} />; break;
        case "exam": icon = <PiBookOpenTextDuotone size={size} className={className} color={color} />; break;
        case "examPaper": icon = <PiBookOpenTextDuotone size={size} className={className} color={color} />; break;
        case "moduleTest": icon = <PiBookOpenTextDuotone size={size} className={className} color={color} />; break;
        case "caretRight": icon = <FaAngleRight size={size} className={className} color={color} />; break;
        case "accordionOpen": icon = <FaCaretDown size={size} className={className} color={color} />; break;
        case "accordionClosed": icon = <FaCaretRight size={size} className={className} color={color} />; break;
        case "caretDoubleLeft": icon = <PiCaretDoubleLeft size={size} className={className} color={color} />; break;
        case "caretDoubleRight": icon = <PiCaretDoubleRight size={size} className={className} color={color} />; break;
        case "user": icon = <FaUser size={size} className={className} color={color} />; break;
        case "signout": icon = <FaSignOutAlt size={size} className={className} color={color} />; break;
        case "error": icon = <PiWarningCircleDuotone size={size} className={className} color={color} />; break;
        case "check": icon = <FaCheck size={size} className={className} color={color} />; break;
        case "checkCircle": icon = <FaCheckCircle size={size} className={className} color={color} />; break;
        case "checkbox": icon = <FaRegSquare size={size} className={className} color={color} />; break;
        case "checkboxChecked": icon = <FaCheckSquare size={size} className={className} color={color} />; break;
        case "cross": icon = <PiXCircleDuotone size={size} className={className} color={color} />; break;
        case "question": icon = <FaQuestionCircle size={size} className={className} color={color} />; break;
        case "info": icon = <FaInfoCircle size={size} className={className} color={color} />; break;
        case "spinner": icon = <PiSpinnerGapThin size={size} className={className} color={color} />; break;
        case "spinner2": icon = <PiSpinner size={size} className={className} color={color} />; break;
        case "clock": icon = <FaRegClock size={size} className={className} color={color} />; break;
        case "sailboat": icon = <FaSailboat size={size} className={className} color={color} />; break;
        case "radioChecked": icon = <MdOutlineRadioButtonChecked size={size} className={className} color={color} />; break;
        case "radioUnchecked": icon = <MdOutlineRadioButtonUnchecked size={size} className={className} color={color} />; break;
        case "instructorTips": icon = <FaAnchor size={size} className={className} color={color} />; break;
        case "resources": icon = <FaBookOpen size={size} className={className} color={color} />; break;
        case "video": icon = <FaVideo size={size} className={className} color={color} />; break;
        case "pdf": icon = <FaFilePdf size={size} className={className} color={color} />; break;
        case "arrow-right": icon = <FaArrowCircleRight size={size} className={className} color={color} />; break;
        case "arrow-left": icon = <FaArrowCircleLeft size={size} className={className} color={color} />; break;
        case "menu": icon = <FaBars size={size} className={className} color={color} />; break;
        case "warning": icon = <FaExclamationCircle size={size} className={className} color={color} />; break;
        case "progress": icon = <FaArchive size={size} className={className} color={color} />; break;
        case "folder": icon = <FaFolder size={size} className={className} color={color} />; break;
        case "mail": icon = <FaEnvelope size={size} className={className} color={color} />; break;
        case "pencil": icon = <FaPencil size={size} className={className} color={color} />; break;
        case "book": icon = <FaBook size={size} className={className} color={color} />; break;
        case "almanac": icon = <FaBookAtlas size={size} className={className} color={color} />; break;
        case "refresh": icon = <FaSync size={size} className={className} color={color} />; break;
        case "return": icon = <MdKeyboardReturn size={size} className={className} color={color} />; break;
        case "clear": icon = <FaXmark size={size} className={className} color={color} />; break;
        case "save": icon = <FaSave size={size} className={className} color={color} />; break;
        case "file-picture": icon = <FaImage size={size} className={className} color={color} />; break;
        case "triangle": icon = <FaCaretRight size={size} className={className} color={color} />; break;
        case "circle": icon = <FaCircle size={size} className={className} color={color} />; break;
        default: break;
    }

    // HH: Middle design
    //     <div className="relative w-0" style={{ left: `-${((size * 0.7)) + (size * 0.15)}px`, top: `${(size * 0.2)}px` }}>
    //     <div className="bg-white rounded-full flex justify-evenly items-center" style={{ width: (size * 0.7), height: (size * 0.7), border: `1px solid ${color}` }}><UTIcon type={extra} size={size * 0.6} color={color} />
    //     </div>
    // </div>

    let title = _.startCase(type);

    if (extra) {
        title = `${title} ${_.startCase(extra)}`;
        icon = <>
            {icon}
            <div className={"relative w-0"} style={{ left: `${(-size / 4) - 4}px`, top: `${(size - (size * 0.6))}px` }}>
                <div className={"bg-white rounded-full flex justify-evenly items-center"} style={{ width: (size * 0.7), height: (size * 0.7), border: `1px solid ${color}` }}><UTIcon type={extra} size={size * 0.6} color={color} />
                </div>
            </div>
        </>
    }

    return <div title={title} className={`flex ${divClassName}`} onClick={onClick}>{icon}</div>;
}

export default UTIcon;